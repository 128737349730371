<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      location: null,
      isAuthError: false,
      processing: false,
      tennatFound: false,
      errorMessage: null,

      // role: null,
      // locations: [
      //   { text: "Choose Location ", value: null },
      //   "United State",
      //   "United Kingdom",
      // ],
      // roles: [
      //   { text: "Role ", value: null },
      //   "Super Admin",
      //   "Admin",
      //   "Shift Manager",
      //   "Employee",
      // ],
    };
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");

    // this.checkTenetStatus();
  },
  methods: {
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      let self = this;
      this.$v.$touch();
      if (self.processing === true) {
        return false;
      }

      if (this.$v.$invalid) {
        return;
      } else {
        this.tryingToLogIn = true;
        // Reset the authError if it existed.
        this.authError = null;
        this.processing = true;
        let self = this;
        this.$store
          .dispatch("auth/logIn", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response.data.status !== true) {
              self.triggerSwal(response.data.message, "error");
            } else {
              self.triggerSwal(response.data.message, "success");
            }

            self.processing = false;
          })
          .catch((error) => {
            self.handleErrorResponse(error.response, "error");
          })
          .finally(function () {
            self.processing = false;
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="account-page overflow-hidden vh-100">
      <div class="container-fluid ">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-6">
            <div class="bg-white p-4 radius-20 w-75 mx-auto">
              <div class="inner-wrapper text-center">
                <div class="logo-wrapper mb-3">
                  <img src="../../../../src/assets/images/adminpanelimages/login-logo.png" alt="logo" />
                </div>
                <div class="text-wrapper">
                  <p class="fw-bold montserrat f-24 mb-0">
                    Login
                  </p>

                </div>
                <p class="mb-4">Welcome! Please enter you details.</p>
              </div>

              <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

              <div v-if="notification.message" :class="'alert ' + notification.type">
                {{ notification.message }}
              </div>
              <b-form @submit.prevent="tryToLogIn">
                <div class="form-floating mb-4">
                  <b-form-input id="input-1" v-model="email" type="text" class="form-control rounded-pill"
                    placeholder="Email address" :class="{ 'is-invalid': submitted && $v.email.$error }"></b-form-input>
                  <label for="input-1">Email address</label>
                  <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                    <span v-if="!$v.email.required">Email is required.</span>
                    <span v-if="!$v.email.email">Please enter a valid email.</span>
                  </div>
                </div>


                <!-- <b-form @submit.prevent="tryToLogIn">
                <b-form-group id="input-group-1" class="mb-3" label="Email" label-for="input-1">
                  <b-form-input id="input-1" v-model="email" type="text" class="form-control"
                    placeholder="Email address" :class="{ 'is-invalid': submitted && $v.email.$error }"></b-form-input>
                  <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                    <span v-if="!$v.email.required">Email is required.</span>
                    <span v-if="!$v.email.email">Please enter valid email.</span>
                  </div>
                </b-form-group> -->
                <!-- <b-form-group
                   
                    label="Location:"
                    label-for="input-3"
                    class="mb-3"
                  >
                    <b-form-select
                      class="form-control py-2"
                      
                      v-model="location"
                      :options="locations"
                      required
                    ></b-form-select>
                </b-form-group>-->
                <!-- <b-form-group
                   
                    label="Role:"
                    label-for="input-3"
                    class="mb-3"
                  >
                    <b-form-select
                      class="form-control py-2"
                      
                      v-model="role"
                      :options="roles"
                      required
                    ></b-form-select>
                </b-form-group>-->

                <!-- <b-form-group id="input-group-2" class="mb-4">
                  <label for="input-2">Password</label>
                  <b-form-input id="input-2" v-model="password" type="password" placeholder="Password" :class="{
                    'is-invalid': submitted && $v.password.$error,
                  }"></b-form-input>
                  <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                    Password is required.
                  </div>
                </b-form-group> -->

                <div class="form-floating mb-3">
                  <b-form-input id="input-group-2" v-model="password" type="password" class="form-control rounded-pill"
                    placeholder="Password" :class="{ 'is-invalid': submitted && $v.password.$error }"></b-form-input>
                  <label for="input-group-2">Password</label>
                  <div v-if="submitted && $v.password.$error" class="invalid-feedback">
                    <span v-if="!$v.password.required">Password is required.</span>
                    <span v-if="!$v.password.password">Please enter a valid password.</span>
                  </div>
                </div>


                <div class="mt-4 text-end">
                  <!-- <b-button type="submit"  v-on:click="tryingToLogIn" variant="primary" class="submit-btn w-100 gradientColor border-0 radius-30 py-2 fw-bold"
                      >
                      
                      <template v-if="processing" >
                       <loader />
                      </template>
<template v-else>Login</template>



</b-button-->

                  <SubmitButton :processing="processing" text="Sign In" @clicked="tryToLogIn" />

                  <div class="d-flex align-items-center justify-content-between m-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="auth-remember-check" />
                      <label class="form-check-label mb-0 f-14" for="auth-remember-check">Remember me</label>
                    </div>
                    <a href="/forgot-password">
                      <p class="mb-0 orange-text">Forget Password</p>
                    </a>
                  </div>

                </div>

                <!-- <div class="mt-3 text-center">
                        <div class="signin-other-title">
                          <h5 class=" mb-4 title">OR <span>Login</span> with</h5>
                        </div>
  
                        <ul class="list-inline">
                          <li class="list-inline-item">
                            <a
                              href="javascript:void()"
                            >
                              <img src="@/assets/images/twitter-icon.svg" />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              href="javascript:void()"
                            >
                              <img src="@/assets/images/gmail-icon.svg" />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              href="javascript:void()"
                            >
                              <img src="@/assets/images/facebook-icon.svg" />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              href="javascript:void()"
                            >
                              <img src="@/assets/images/apple-icon.svg" />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              href="javascript:void()"
                            >
                              <img src="@/assets/images/window-icon.svg" />
                            </a>
                          </li>
                        </ul>
                </div>-->
              </b-form>

              <!-- end card-body -->
            </div>
          </div>
          <div class="col-md-6">
            <img src="../../../../src/assets/images/adminpanelimages/loginimg.png"
              class="img-fluid w-100 object-fit-cover vh-100">
          </div>
          <!-- end card -->

          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" module></style>
